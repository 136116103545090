@import '../../StyleDefaults/variables';

.el-ToDoFilter{
	width: 100%;
	height: auto;
	min-height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	&-item{
		width: 80px;
		text-align: center;
		opacity: 0.9;
		font-size: $font-size-large;
		&:hover{
			cursor: pointer;
			opacity: 1;
		}
		&:after{
			content: '/';
			position: relative;
			top: 0;
			right: 0;
			margin-left: 10px;
		}
		&:last-child{
			&:after{
				content: '';
				display: none;
			}
		}
	}
}