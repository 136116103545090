@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

@media (min-width: 1024px) {
  .el-Input {
    box-sizing: border-box;
    width: 100%; }
    .el-Input-text {
      width: 100%;
      height: 40px;
      font-size: 28px;
      color: #ffffff;
      background-color: #222222;
      border: none;
      box-sizing: border-box;
      padding-right: 10px; }
      .el-Input-text:focus {
        outline: none; }
      .el-Input-text:hover {
        cursor: pointer; }
    .el-Input-activeToDo .el-Input-text {
      text-decoration: line-through;
      -webkit-text-decoration-color: #03DaC6;
              text-decoration-color: #03DaC6; }
    .el-Input-editModeToDo {
      width: 620px !important; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-Input {
    box-sizing: border-box;
    width: 100%; }
    .el-Input-text {
      width: 100%;
      height: 40px;
      font-size: 28px;
      color: #ffffff;
      background-color: #222222;
      border: none;
      box-sizing: border-box;
      padding-right: 10px; }
      .el-Input-text:focus {
        outline: none; }
      .el-Input-text:hover {
        cursor: pointer; }
    .el-Input-activeToDo .el-Input-text {
      text-decoration: line-through;
      -webkit-text-decoration-color: #03DaC6;
              text-decoration-color: #03DaC6; }
    .el-Input-editModeToDo {
      width: 620px !important; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-Input {
    box-sizing: border-box;
    width: 100%; }
    .el-Input-text {
      width: 100%;
      height: 40px;
      font-size: 20px;
      color: #ffffff;
      background-color: #222222;
      border: none;
      box-sizing: border-box;
      padding-right: 10px; }
      .el-Input-text:focus {
        outline: none; }
      .el-Input-text:hover {
        cursor: pointer; }
    .el-Input-activeToDo .el-Input-text {
      text-decoration: line-through;
      -webkit-text-decoration-color: #03DaC6;
              text-decoration-color: #03DaC6; }
    .el-Input-editModeToDo {
      width: 225px !important; } }

@media (min-width: 1024px) {
  .el-Button-check {
    display: flex;
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-check-disabled {
      display: none; }
    .el-Button-check:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-check .fa, .el-Button-check .fa-check, .el-Button-check .fa-pencil, .el-Button-check .fa-trash, .el-Button-check .fa-times {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-Button-check {
    display: flex;
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-check-disabled {
      display: none; }
    .el-Button-check:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-check .fa, .el-Button-check .fa-check, .el-Button-check .fa-pencil, .el-Button-check .fa-trash, .el-Button-check .fa-times {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-Button-check {
    display: flex;
    width: 31px;
    height: 31px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-check-disabled {
      display: none; }
    .el-Button-check:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-check .fa, .el-Button-check .fa-check, .el-Button-check .fa-pencil, .el-Button-check .fa-trash, .el-Button-check .fa-times {
      width: 15px;
      height: 15px;
      font-size: 15px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 1024px) {
  .el-Button-pencil {
    display: flex;
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-pencil-disabled {
      display: none; }
    .el-Button-pencil:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-pencil .fa, .el-Button-pencil .fa-check, .el-Button-pencil .fa-pencil, .el-Button-pencil .fa-trash, .el-Button-pencil .fa-times {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-Button-pencil {
    display: flex;
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-pencil-disabled {
      display: none; }
    .el-Button-pencil:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-pencil .fa, .el-Button-pencil .fa-check, .el-Button-pencil .fa-pencil, .el-Button-pencil .fa-trash, .el-Button-pencil .fa-times {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-Button-pencil {
    display: flex;
    width: 31px;
    height: 31px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-pencil-disabled {
      display: none; }
    .el-Button-pencil:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-pencil .fa, .el-Button-pencil .fa-check, .el-Button-pencil .fa-pencil, .el-Button-pencil .fa-trash, .el-Button-pencil .fa-times {
      width: 15px;
      height: 15px;
      font-size: 15px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 1024px) {
  .el-Button-trash {
    display: flex;
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-trash-disabled {
      display: none; }
    .el-Button-trash:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-trash .fa, .el-Button-trash .fa-check, .el-Button-trash .fa-pencil, .el-Button-trash .fa-trash, .el-Button-trash .fa-times {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-Button-trash {
    display: flex;
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-trash-disabled {
      display: none; }
    .el-Button-trash:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-trash .fa, .el-Button-trash .fa-check, .el-Button-trash .fa-pencil, .el-Button-trash .fa-trash, .el-Button-trash .fa-times {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-Button-trash {
    display: flex;
    width: 31px;
    height: 31px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-trash-disabled {
      display: none; }
    .el-Button-trash:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-trash .fa, .el-Button-trash .fa-check, .el-Button-trash .fa-pencil, .el-Button-trash .fa-trash, .el-Button-trash .fa-times {
      width: 15px;
      height: 15px;
      font-size: 15px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 1024px) {
  .el-Button-times {
    display: flex;
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-times-disabled {
      display: none; }
    .el-Button-times:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-times .fa, .el-Button-times .fa-check, .el-Button-times .fa-pencil, .el-Button-times .fa-trash, .el-Button-times .fa-times {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-Button-times {
    display: flex;
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-times-disabled {
      display: none; }
    .el-Button-times:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-times .fa, .el-Button-times .fa-check, .el-Button-times .fa-pencil, .el-Button-times .fa-trash, .el-Button-times .fa-times {
      width: 20px;
      height: 20px;
      font-size: 20px;
      font-weight: lighter;
      text-align: center; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-Button-times {
    display: flex;
    width: 31px;
    height: 31px;
    transition: all 0.3s ease-in-out;
    background-color: darkgray;
    border-radius: 25px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    box-sizing: border-box;
    margin: 2px; }
    .el-Button-times-disabled {
      display: none; }
    .el-Button-times:hover {
      opacity: 1;
      cursor: pointer; }
    .el-Button-times .fa, .el-Button-times .fa-check, .el-Button-times .fa-pencil, .el-Button-times .fa-trash, .el-Button-times .fa-times {
      width: 15px;
      height: 15px;
      font-size: 15px;
      font-weight: lighter;
      text-align: center; } }

.el-Separator {
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #ffffff;
  box-sizing: border-box;
  margin-top: 10px; }

@media (min-width: 1024px) {
  .el-ToDoAdd {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .el-ToDoAdd .el-Input {
      width: 620px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-ToDoAdd {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .el-ToDoAdd .el-Input {
      width: 620px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-ToDoAdd {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .el-ToDoAdd .el-Input {
      width: 225px !important; } }

.el-ToDoFilter {
  width: 100%;
  height: auto;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .el-ToDoFilter-item {
    width: 80px;
    text-align: center;
    opacity: 0.9;
    font-size: 18px; }
    .el-ToDoFilter-item:hover {
      cursor: pointer;
      opacity: 1; }
    .el-ToDoFilter-item:after {
      content: '/';
      position: relative;
      top: 0;
      right: 0;
      margin-left: 10px; }
    .el-ToDoFilter-item:last-child:after {
      content: '';
      display: none; }

@media (min-width: 1024px) {
  .el-ToDoItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .el-ToDoItem .el-Input {
      width: 539px; }
      .el-ToDoItem .el-Input-text {
        padding-left: 10px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-ToDoItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .el-ToDoItem .el-Input {
      width: 539px; }
      .el-ToDoItem .el-Input-text {
        padding-left: 10px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-ToDoItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .el-ToDoItem .el-Input {
      width: 154px; }
      .el-ToDoItem .el-Input-text {
        padding-left: 5px; } }

@media (min-width: 1024px) {
  .el-ToDoItems {
    width: 100%;
    height: 500px;
    overflow-y: auto; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-ToDoItems {
    width: 100%;
    height: auto;
    max-height: 500px;
    overflow-y: auto; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-ToDoItems {
    width: 100%;
    height: 215px;
    max-height: 215px;
    overflow-y: auto; } }

@media (min-width: 1024px) {
  .el-ToDoWrapper {
    width: 700px;
    min-width: 500px;
    height: auto;
    box-sizing: border-box;
    padding: 20px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-ToDoWrapper {
    width: 700px;
    min-width: 500px;
    height: auto;
    box-sizing: border-box;
    padding: 20px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-ToDoWrapper {
    width: 300px;
    min-width: 280px;
    height: auto;
    box-sizing: border-box;
    padding: 20px; } }

@font-face {
  font-family: 'ProximaThin';
  src: url(/static/media/Proxima_Nova_Alt_Thin.58b3863f.otf); }

* {
  margin: 0;
  padding: 0;
  font-family: 'ProximaThin', sans-serif; }

/* width */
::-webkit-scrollbar {
  width: 1px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

@media (min-width: 1024px) {
  .el-App {
    width: 100%;
    height: 100vh;
    background-color: #222222;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .el-App {
    width: 100%;
    height: 100vh;
    background-color: #222222;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 320px) and (max-width: 767px) {
  .el-App {
    width: 100%;
    height: 100vh;
    background-color: #222222;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center; } }

