@import '../../StyleDefaults/mixins';

.el-ToDoItem{
	@include desktop{
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.el-Input{
			width: 539px;
			&-text{
				padding-left: 10px;
			}
		}
	}
	@include tablet{
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.el-Input{
			width: 539px;
			&-text{
				padding-left: 10px;
			}
		}
	}
	@include mobile{
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.el-Input{
			width: 154px;
			&-text{
				padding-left: 5px;
			}
		}
	}
}