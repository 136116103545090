@import '../../StyleDefaults/mixins';

.el-ToDoItems{
	@include desktop{
		width: 100%;
		height: 500px;
		overflow-y: auto;
	}
	@include tablet{
		width: 100%;
		height: auto;
		max-height: 500px;
		overflow-y: auto;
	}
	@include mobile{
		width: 100%;
		height: 215px;
		max-height: 215px;
		overflow-y: auto;
	}
}