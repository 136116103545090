@import '../StyleDefaults/reset';
@import '../StyleDefaults/variables';
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import '../StyleDefaults/mixins';

/* width */
::-webkit-scrollbar {
    width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.el-App{
	@include desktop{
		width: 100%;
		height: 100vh;
		background-color: $background-color;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@include tablet{
		width: 100%;
		height: 100vh;
		background-color: $background-color;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@include mobile{
		width: 100%;
		height: 100vh;
		background-color: $background-color;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}