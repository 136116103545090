@import '../../StyleDefaults/mixins';

.el-ToDoAdd{
	@include desktop{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.el-Input{
			width: 620px;
		}
	}
	@include tablet{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.el-Input{
			width: 620px;
		}
	}
	@include mobile{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.el-Input{
			width: 225px !important;
		}
	}
}